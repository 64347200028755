export const POKEMON_LIST = [
  {
    name: "Bulbasaur",
    pokedex_num: 1,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Ivysaur",
    pokedex_num: 2,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Venusaur",
    pokedex_num: 3,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Charmander",
    pokedex_num: 4,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Charmeleon",
    pokedex_num: 5,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Charizard",
    pokedex_num: 6,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Squirtle",
    pokedex_num: 7,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Wartortle",
    pokedex_num: 8,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Blastoise",
    pokedex_num: 9,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Caterpie",
    pokedex_num: 10,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Metapod",
    pokedex_num: 11,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Butterfree",
    pokedex_num: 12,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Weedle",
    pokedex_num: 13,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Kakuna",
    pokedex_num: 14,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Beedrill",
    pokedex_num: 15,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Pidgey",
    pokedex_num: 16,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Pidgeotto",
    pokedex_num: 17,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Pidgeot",
    pokedex_num: 18,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Rattata",
    pokedex_num: 19,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Raticate",
    pokedex_num: 20,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Spearow",
    pokedex_num: 21,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Fearow",
    pokedex_num: 22,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Ekans",
    pokedex_num: 23,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Arbok",
    pokedex_num: 24,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Pikachu",
    pokedex_num: 25,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Raichu",
    pokedex_num: 26,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Sandshrew",
    pokedex_num: 27,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Sandslash",
    pokedex_num: 28,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Nidoran♀",
    pokedex_num: 29,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Nidorina",
    pokedex_num: 30,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Nidoqueen",
    pokedex_num: 31,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Nidoran♂",
    pokedex_num: 32,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Nidorino",
    pokedex_num: 33,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Nidoking",
    pokedex_num: 34,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Clefairy",
    pokedex_num: 35,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Clefable",
    pokedex_num: 36,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Vulpix",
    pokedex_num: 37,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Ninetales",
    pokedex_num: 38,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Jigglypuff",
    pokedex_num: 39,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Wigglytuff",
    pokedex_num: 40,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Zubat",
    pokedex_num: 41,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", "PLA"],
  },
  {
    name: "Golbat",
    pokedex_num: 42,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", "PLA"],
  },
  {
    name: "Oddish",
    pokedex_num: 43,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Gloom",
    pokedex_num: 44,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Vileplume",
    pokedex_num: 45,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Paras",
    pokedex_num: 46,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", 'PLA'],
  },
  {
    name: "Parasect",
    pokedex_num: 47,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Venonat",
    pokedex_num: 48,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Venomoth",
    pokedex_num: 49,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Diglett",
    pokedex_num: 50,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Dugtrio",
    pokedex_num: 51,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Meowth",
    pokedex_num: 52,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Persian",
    pokedex_num: 53,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Psyduck",
    pokedex_num: 54,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Golduck",
    pokedex_num: 55,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Mankey",
    pokedex_num: 56,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Primeape",
    pokedex_num: 57,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Growlithe",
    pokedex_num: 58,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Arcanine",
    pokedex_num: 59,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Poliwag",
    pokedex_num: 60,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Poliwhirl",
    pokedex_num: 61,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Poliwrath",
    pokedex_num: 62,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Abra",
    pokedex_num: 63,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Kadabra",
    pokedex_num: 64,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Alakazam",
    pokedex_num: 65,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Machop",
    pokedex_num: 66,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Machoke",
    pokedex_num: 67,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Machamp",
    pokedex_num: 68,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Bellsprout",
    pokedex_num: 69,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Weepinbell",
    pokedex_num: 70,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Victreebel",
    pokedex_num: 71,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Tentacool",
    pokedex_num: 72,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Tentacruel",
    pokedex_num: 73,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Geodude",
    pokedex_num: 74,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", 'PLA'],
  },
  {
    name: "Graveler",
    pokedex_num: 75,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", 'PLA'],
  },
  {
    name: "Golem",
    pokedex_num: 76,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", 'PLA'],
  },
  {
    name: "Ponyta",
    pokedex_num: 77,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Rapidash",
    pokedex_num: 78,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Slowpoke",
    pokedex_num: 79,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Slowbro",
    pokedex_num: 80,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Magnemite",
    pokedex_num: 81,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Magneton",
    pokedex_num: 82,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Farfetch'd",
    pokedex_num: 83,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Doduo",
    pokedex_num: 84,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Dodrio",
    pokedex_num: 85,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Seel",
    pokedex_num: 86,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Dewgong",
    pokedex_num: 87,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Grimer",
    pokedex_num: 88,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Muk",
    pokedex_num: 89,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Shellder",
    pokedex_num: 90,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Cloyster",
    pokedex_num: 91,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Gastly",
    pokedex_num: 92,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Haunter",
    pokedex_num: 93,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Gengar",
    pokedex_num: 94,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Onix",
    pokedex_num: 95,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Drowzee",
    pokedex_num: 96,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Hypno",
    pokedex_num: 97,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Krabby",
    pokedex_num: 98,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Kingler",
    pokedex_num: 99,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Voltorb",
    pokedex_num: 100,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", "PLA"],
  },
  {
    name: "Electrode",
    pokedex_num: 101,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", "PLA"],
  },
  {
    name: "Exeggcute",
    pokedex_num: 102,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Exeggutor",
    pokedex_num: 103,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Cubone",
    pokedex_num: 104,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Marowak",
    pokedex_num: 105,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Hitmonlee",
    pokedex_num: 106,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Hitmonchan",
    pokedex_num: 107,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Lickitung",
    pokedex_num: 108,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Koffing",
    pokedex_num: 109,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Weezing",
    pokedex_num: 110,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Rhyhorn",
    pokedex_num: 111,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Rhydon",
    pokedex_num: 112,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Chansey",
    pokedex_num: 113,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Tangela",
    pokedex_num: 114,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Kangaskhan",
    pokedex_num: 115,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Horsea",
    pokedex_num: 116,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Seadra",
    pokedex_num: 117,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Goldeen",
    pokedex_num: 118,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Seaking",
    pokedex_num: 119,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Staryu",
    pokedex_num: 120,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Starmie",
    pokedex_num: 121,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Mr. Mime",
    pokedex_num: 122,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Scyther",
    pokedex_num: 123,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Jynx",
    pokedex_num: 124,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Electabuzz",
    pokedex_num: 125,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", 'PLA'],
  },
  {
    name: "Magmar",
    pokedex_num: 126,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP", 'PLA'],
  },
  {
    name: "Pinsir",
    pokedex_num: 127,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Tauros",
    pokedex_num: 128,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Magikarp",
    pokedex_num: 129,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Gyarados",
    pokedex_num: 130,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", 'PLA'],
  },
  {
    name: "Lapras",
    pokedex_num: 131,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Ditto",
    pokedex_num: 132,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Eevee",
    pokedex_num: 133,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Vaporeon",
    pokedex_num: 134,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Jolteon",
    pokedex_num: 135,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Flareon",
    pokedex_num: 136,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Porygon",
    pokedex_num: 137,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Omanyte",
    pokedex_num: 138,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Omastar",
    pokedex_num: 139,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Kabuto",
    pokedex_num: 140,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Kabutops",
    pokedex_num: 141,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Aerodactyl",
    pokedex_num: 142,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Snorlax",
    pokedex_num: 143,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP", "PLA"],
  },
  {
    name: "Articuno",
    pokedex_num: 144,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Zapdos",
    pokedex_num: 145,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Moltres",
    pokedex_num: 146,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Dratini",
    pokedex_num: 147,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Dragonair",
    pokedex_num: 148,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Dragonite",
    pokedex_num: 149,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "BDSP"],
  },
  {
    name: "Mewtwo",
    pokedex_num: 150,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Mew",
    pokedex_num: 151,
    available_generations: ["1", "2", "3", "4", "5", "6", "7", "LGPE", "8", "BDSP"],
  },
  {
    name: "Chikorita",
    pokedex_num: 152,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Bayleef",
    pokedex_num: 153,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Meganium",
    pokedex_num: 154,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Cyndaquil",
    pokedex_num: 155,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Quilava",
    pokedex_num: 156,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Typhlosion",
    pokedex_num: 157,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Totodile",
    pokedex_num: 158,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Croconaw",
    pokedex_num: 159,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Feraligatr",
    pokedex_num: 160,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Sentret",
    pokedex_num: 161,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Furret",
    pokedex_num: 162,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Hoothoot",
    pokedex_num: 163,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Noctowl",
    pokedex_num: 164,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Ledyba",
    pokedex_num: 165,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Ledian",
    pokedex_num: 166,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Spinarak",
    pokedex_num: 167,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Ariados",
    pokedex_num: 168,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Crobat",
    pokedex_num: 169,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Chinchou",
    pokedex_num: 170,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Lanturn",
    pokedex_num: 171,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Pichu",
    pokedex_num: 172,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Cleffa",
    pokedex_num: 173,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Igglybuff",
    pokedex_num: 174,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Togepi",
    pokedex_num: 175,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Togetic",
    pokedex_num: 176,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Natu",
    pokedex_num: 177,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Xatu",
    pokedex_num: 178,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Mareep",
    pokedex_num: 179,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Flaaffy",
    pokedex_num: 180,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Ampharos",
    pokedex_num: 181,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Bellossom",
    pokedex_num: 182,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Marill",
    pokedex_num: 183,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Azumarill",
    pokedex_num: 184,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Sudowoodo",
    pokedex_num: 185,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Politoed",
    pokedex_num: 186,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Hoppip",
    pokedex_num: 187,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Skiploom",
    pokedex_num: 188,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Jumpluff",
    pokedex_num: 189,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Aipom",
    pokedex_num: 190,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Sunkern",
    pokedex_num: 191,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Sunflora",
    pokedex_num: 192,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Yanma",
    pokedex_num: 193,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Wooper",
    pokedex_num: 194,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Quagsire",
    pokedex_num: 195,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Espeon",
    pokedex_num: 196,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Umbreon",
    pokedex_num: 197,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Murkrow",
    pokedex_num: 198,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Slowking",
    pokedex_num: 199,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Misdreavus",
    pokedex_num: 200,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Unown",
    pokedex_num: 201,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Wobbuffet",
    pokedex_num: 202,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Girafarig",
    pokedex_num: 203,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Pineco",
    pokedex_num: 204,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Forretress",
    pokedex_num: 205,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Dunsparce",
    pokedex_num: 206,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Gligar",
    pokedex_num: 207,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Steelix",
    pokedex_num: 208,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Snubbull",
    pokedex_num: 209,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Granbull",
    pokedex_num: 210,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Qwilfish",
    pokedex_num: 211,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Scizor",
    pokedex_num: 212,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Shuckle",
    pokedex_num: 213,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Heracross",
    pokedex_num: 214,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Sneasel",
    pokedex_num: 215,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Teddiursa",
    pokedex_num: 216,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Ursaring",
    pokedex_num: 217,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Slugma",
    pokedex_num: 218,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Magcargo",
    pokedex_num: 219,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Swinub",
    pokedex_num: 220,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Piloswine",
    pokedex_num: 221,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Corsola",
    pokedex_num: 222,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Remoraid",
    pokedex_num: 223,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Octillery",
    pokedex_num: 224,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Delibird",
    pokedex_num: 225,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Mantine",
    pokedex_num: 226,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Skarmory",
    pokedex_num: 227,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Houndour",
    pokedex_num: 228,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Houndoom",
    pokedex_num: 229,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Kingdra",
    pokedex_num: 230,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Phanpy",
    pokedex_num: 231,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Donphan",
    pokedex_num: 232,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Porygon2",
    pokedex_num: 233,
    available_generations: ["8", "BDSP", 'PLA'],
  },
  {
    name: "Stantler",
    pokedex_num: 234,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Smeargle",
    pokedex_num: 235,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Tyrogue",
    pokedex_num: 236,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Hitmontop",
    pokedex_num: 237,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Smoochum",
    pokedex_num: 238,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Elekid",
    pokedex_num: 239,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Magby",
    pokedex_num: 240,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Miltank",
    pokedex_num: 241,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Blissey",
    pokedex_num: 242,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Raikou",
    pokedex_num: 243,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Entei",
    pokedex_num: 244,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Suicune",
    pokedex_num: 245,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Larvitar",
    pokedex_num: 246,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Pupitar",
    pokedex_num: 247,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Tyranitar",
    pokedex_num: 248,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Lugia",
    pokedex_num: 249,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Ho-oh",
    pokedex_num: 250,
    available_generations: ["2", "3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Celebi",
    pokedex_num: 251,
    available_generations: ["2", "3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Treecko",
    pokedex_num: 252,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Grovyle",
    pokedex_num: 253,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Sceptile",
    pokedex_num: 254,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Torchic",
    pokedex_num: 255,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Combusken",
    pokedex_num: 256,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Blaziken",
    pokedex_num: 257,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Mudkip",
    pokedex_num: 258,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Marshtomp",
    pokedex_num: 259,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Swampert",
    pokedex_num: 260,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Poochyena",
    pokedex_num: 261,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Mightyena",
    pokedex_num: 262,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Zigzagoon",
    pokedex_num: 263,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Linoone",
    pokedex_num: 264,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Wurmple",
    pokedex_num: 265,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Silcoon",
    pokedex_num: 266,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Beautifly",
    pokedex_num: 267,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Cascoon",
    pokedex_num: 268,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Dustox",
    pokedex_num: 269,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Lotad",
    pokedex_num: 270,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Lombre",
    pokedex_num: 271,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Ludicolo",
    pokedex_num: 272,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Seedot",
    pokedex_num: 273,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Nuzleaf",
    pokedex_num: 274,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Shiftry",
    pokedex_num: 275,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Taillow",
    pokedex_num: 276,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Swellow",
    pokedex_num: 277,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Wingull",
    pokedex_num: 278,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Pelipper",
    pokedex_num: 279,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Ralts",
    pokedex_num: 280,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Kirlia",
    pokedex_num: 281,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Gardevoir",
    pokedex_num: 282,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Surskit",
    pokedex_num: 283,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Masquerain",
    pokedex_num: 284,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Shroomish",
    pokedex_num: 285,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Breloom",
    pokedex_num: 286,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Slakoth",
    pokedex_num: 287,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Vigoroth",
    pokedex_num: 288,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Slaking",
    pokedex_num: 289,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Nincada",
    pokedex_num: 290,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Ninjask",
    pokedex_num: 291,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Shedinja",
    pokedex_num: 292,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Whismur",
    pokedex_num: 293,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Loudred",
    pokedex_num: 294,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Exploud",
    pokedex_num: 295,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Makuhita",
    pokedex_num: 296,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Hariyama",
    pokedex_num: 297,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Azurill",
    pokedex_num: 298,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Nosepass",
    pokedex_num: 299,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Skitty",
    pokedex_num: 300,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Delcatty",
    pokedex_num: 301,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Sableye",
    pokedex_num: 302,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Mawile",
    pokedex_num: 303,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Aron",
    pokedex_num: 304,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Lairon",
    pokedex_num: 305,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Aggron",
    pokedex_num: 306,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Meditite",
    pokedex_num: 307,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Medicham",
    pokedex_num: 308,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Electrike",
    pokedex_num: 309,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Manectric",
    pokedex_num: 310,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Plusle",
    pokedex_num: 311,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Minun",
    pokedex_num: 312,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Volbeat",
    pokedex_num: 313,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Illumise",
    pokedex_num: 314,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Roselia",
    pokedex_num: 315,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Gulpin",
    pokedex_num: 316,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Swalot",
    pokedex_num: 317,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Carvanha",
    pokedex_num: 318,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Sharpedo",
    pokedex_num: 319,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Wailmer",
    pokedex_num: 320,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Wailord",
    pokedex_num: 321,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Numel",
    pokedex_num: 322,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Camerupt",
    pokedex_num: 323,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Torkoal",
    pokedex_num: 324,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Spoink",
    pokedex_num: 325,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Grumpig",
    pokedex_num: 326,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Spinda",
    pokedex_num: 327,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Trapinch",
    pokedex_num: 328,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Vibrava",
    pokedex_num: 329,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Flygon",
    pokedex_num: 330,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Cacnea",
    pokedex_num: 331,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Cacturne",
    pokedex_num: 332,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Swablu",
    pokedex_num: 333,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Altaria",
    pokedex_num: 334,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Zangoose",
    pokedex_num: 335,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Seviper",
    pokedex_num: 336,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Lunatone",
    pokedex_num: 337,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Solrock",
    pokedex_num: 338,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Barboach",
    pokedex_num: 339,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Whiscash",
    pokedex_num: 340,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Corphish",
    pokedex_num: 341,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Crawdaunt",
    pokedex_num: 342,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Baltoy",
    pokedex_num: 343,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Claydol",
    pokedex_num: 344,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Lileep",
    pokedex_num: 345,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Cradily",
    pokedex_num: 346,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Anorith",
    pokedex_num: 347,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Armaldo",
    pokedex_num: 348,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Feebas",
    pokedex_num: 349,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Milotic",
    pokedex_num: 350,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Castform",
    pokedex_num: 351,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Kecleon",
    pokedex_num: 352,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Shuppet",
    pokedex_num: 353,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Banette",
    pokedex_num: 354,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Duskull",
    pokedex_num: 355,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Dusclops",
    pokedex_num: 356,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Tropius",
    pokedex_num: 357,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Chimecho",
    pokedex_num: 358,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Absol",
    pokedex_num: 359,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Wynaut",
    pokedex_num: 360,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Snorunt",
    pokedex_num: 361,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Glalie",
    pokedex_num: 362,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Spheal",
    pokedex_num: 363,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Sealeo",
    pokedex_num: 364,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Walrein",
    pokedex_num: 365,
    available_generations: ["3", "4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Clamperl",
    pokedex_num: 366,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Huntail",
    pokedex_num: 367,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Gorebyss",
    pokedex_num: 368,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Relicanth",
    pokedex_num: 369,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Luvdisc",
    pokedex_num: 370,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Bagon",
    pokedex_num: 371,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Shelgon",
    pokedex_num: 372,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Salamence",
    pokedex_num: 373,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Beldum",
    pokedex_num: 374,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Metang",
    pokedex_num: 375,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Metagross",
    pokedex_num: 376,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Regirock",
    pokedex_num: 377,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Regice",
    pokedex_num: 378,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Registeel",
    pokedex_num: 379,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Latias",
    pokedex_num: 380,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Latios",
    pokedex_num: 381,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Kyogre",
    pokedex_num: 382,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Groudon",
    pokedex_num: 383,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Rayquaza",
    pokedex_num: 384,
    available_generations: ["3", "4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Jirachi",
    pokedex_num: 385,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Deoxys",
    pokedex_num: 386,
    available_generations: ["3", "4", "5", "6", "7", "8", "BDSP"],
  },
  {
    name: "Turtwig",
    pokedex_num: 387,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Grotle",
    pokedex_num: 388,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Torterra",
    pokedex_num: 389,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Chimchar",
    pokedex_num: 390,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Monferno",
    pokedex_num: 391,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Infernape",
    pokedex_num: 392,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Piplup",
    pokedex_num: 393,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Prinplup",
    pokedex_num: 394,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Empoleon",
    pokedex_num: 395,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Starly",
    pokedex_num: 396,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Staravia",
    pokedex_num: 397,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Staraptor",
    pokedex_num: 398,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Bidoof",
    pokedex_num: 399,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Bibarel",
    pokedex_num: 400,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Kricketot",
    pokedex_num: 401,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Kricketune",
    pokedex_num: 402,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Shinx",
    pokedex_num: 403,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Luxio",
    pokedex_num: 404,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Luxray",
    pokedex_num: 405,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Budew",
    pokedex_num: 406,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Roserade",
    pokedex_num: 407,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Cranidos",
    pokedex_num: 408,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Rampardos",
    pokedex_num: 409,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Shieldon",
    pokedex_num: 410,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Bastiodon",
    pokedex_num: 411,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Burmy",
    pokedex_num: 412,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Wormadam",
    pokedex_num: 413,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Mothim",
    pokedex_num: 414,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Combee",
    pokedex_num: 415,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Vespiquen",
    pokedex_num: 416,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Pachirisu",
    pokedex_num: 417,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Buizel",
    pokedex_num: 418,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Floatzel",
    pokedex_num: 419,
    available_generations: ["4", "5", "6", "7", "BDSP", "PLA"],
  },
  {
    name: "Cherubi",
    pokedex_num: 420,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Cherrim",
    pokedex_num: 421,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Shellos",
    pokedex_num: 422,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Gastrodon",
    pokedex_num: 423,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Ambipom",
    pokedex_num: 424,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Drifloon",
    pokedex_num: 425,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Drifblim",
    pokedex_num: 426,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Buneary",
    pokedex_num: 427,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Lopunny",
    pokedex_num: 428,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Mismagius",
    pokedex_num: 429,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Honchkrow",
    pokedex_num: 430,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Glameow",
    pokedex_num: 431,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Purugly",
    pokedex_num: 432,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Chingling",
    pokedex_num: 433,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Stunky",
    pokedex_num: 434,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Skuntank",
    pokedex_num: 435,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Bronzor",
    pokedex_num: 436,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Bronzong",
    pokedex_num: 437,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Bonsly",
    pokedex_num: 438,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Mime Jr.",
    pokedex_num: 439,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Happiny",
    pokedex_num: 440,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Chatot",
    pokedex_num: 441,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Spiritomb",
    pokedex_num: 442,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Gible",
    pokedex_num: 443,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Gabite",
    pokedex_num: 444,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Garchomp",
    pokedex_num: 445,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Munchlax",
    pokedex_num: 446,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Riolu",
    pokedex_num: 447,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Lucario",
    pokedex_num: 448,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Hippopotas",
    pokedex_num: 449,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Hippowdon",
    pokedex_num: 450,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Skorupi",
    pokedex_num: 451,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Drapion",
    pokedex_num: 452,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Croagunk",
    pokedex_num: 453,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Toxicroak",
    pokedex_num: 454,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Carnivine",
    pokedex_num: 455,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Finneon",
    pokedex_num: 456,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Lumineon",
    pokedex_num: 457,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Mantyke",
    pokedex_num: 458,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Snover",
    pokedex_num: 459,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Abomasnow",
    pokedex_num: 460,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Weavile",
    pokedex_num: 461,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Magnezone",
    pokedex_num: 462,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Lickilicky",
    pokedex_num: 463,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Rhyperior",
    pokedex_num: 464,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Tangrowth",
    pokedex_num: 465,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Electivire",
    pokedex_num: 466,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Magmortar",
    pokedex_num: 467,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Togekiss",
    pokedex_num: 468,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Yanmega",
    pokedex_num: 469,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Leafeon",
    pokedex_num: 470,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Glaceon",
    pokedex_num: 471,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", "PLA"],
  },
  {
    name: "Gliscor",
    pokedex_num: 472,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Mamoswine",
    pokedex_num: 473,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Porygon-Z",
    pokedex_num: 474,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Gallade",
    pokedex_num: 475,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Probopass",
    pokedex_num: 476,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Dusknoir",
    pokedex_num: 477,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Froslass",
    pokedex_num: 478,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Rotom",
    pokedex_num: 479,
    available_generations: ["4", "5", "6", "7", "8", "BDSP", 'PLA'],
  },
  {
    name: "Uxie",
    pokedex_num: 480,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Mesprit",
    pokedex_num: 481,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Azelf",
    pokedex_num: 482,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Dialga",
    pokedex_num: 483,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Palkia",
    pokedex_num: 484,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Heatran",
    pokedex_num: 485,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Regigigas",
    pokedex_num: 486,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Giratina",
    pokedex_num: 492,
    available_generations: ["4", "5", "6", "7", "BDSP"],
  },
  {
    name: "Cresselia",
    pokedex_num: 488,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Phione",
    pokedex_num: 489,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Manaphy",
    pokedex_num: 490,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Darkrai",
    pokedex_num: 491,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Shaymin",
    pokedex_num: 492,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Arceus",
    pokedex_num: 493,
    available_generations: ["4", "5", "6", "7", "BDSP", 'PLA'],
  },
  {
    name: "Victini",
    pokedex_num: 494,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Snivy",
    pokedex_num: 495,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Servine",
    pokedex_num: 496,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Serperior",
    pokedex_num: 497,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Tepig",
    pokedex_num: 498,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Pignite",
    pokedex_num: 499,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Emboar",
    pokedex_num: 500,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Oshawott",
    pokedex_num: 501,
    available_generations: ["5", "6", "7", "PLA"],
  },
  {
    name: "Dewott",
    pokedex_num: 502,
    available_generations: ["5", "6", "7", "PLA"],
  },
  {
    name: "Samurott",
    pokedex_num: 503,
    available_generations: ["5", "6", "7", "PLA"],
  },
  {
    name: "Patrat",
    pokedex_num: 504,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Watchog",
    pokedex_num: 505,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Lillipup",
    pokedex_num: 506,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Herdier",
    pokedex_num: 507,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Stoutland",
    pokedex_num: 508,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Purrloin",
    pokedex_num: 509,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Liepard",
    pokedex_num: 510,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Pansage",
    pokedex_num: 511,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Simisage",
    pokedex_num: 512,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Pansear",
    pokedex_num: 513,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Simisear",
    pokedex_num: 514,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Panpour",
    pokedex_num: 515,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Simipour",
    pokedex_num: 516,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Munna",
    pokedex_num: 517,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Musharna",
    pokedex_num: 518,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Pidove",
    pokedex_num: 519,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Tranquill",
    pokedex_num: 520,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Unfezant",
    pokedex_num: 521,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Blitzle",
    pokedex_num: 522,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Zebstrika",
    pokedex_num: 523,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Roggenrola",
    pokedex_num: 524,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Boldore",
    pokedex_num: 525,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Gigalith",
    pokedex_num: 526,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Woobat",
    pokedex_num: 527,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Swoobat",
    pokedex_num: 528,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Drilbur",
    pokedex_num: 529,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Excadrill",
    pokedex_num: 530,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Audino",
    pokedex_num: 531,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Timburr",
    pokedex_num: 532,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Gurdurr",
    pokedex_num: 533,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Conkeldurr",
    pokedex_num: 534,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Tympole",
    pokedex_num: 535,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Palpitoad",
    pokedex_num: 536,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Seismitoad",
    pokedex_num: 537,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Throh",
    pokedex_num: 538,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Sawk",
    pokedex_num: 539,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Sewaddle",
    pokedex_num: 540,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Swadloon",
    pokedex_num: 541,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Leavanny",
    pokedex_num: 542,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Venipede",
    pokedex_num: 543,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Whirlipede",
    pokedex_num: 544,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Scolipede",
    pokedex_num: 545,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Cottonee",
    pokedex_num: 546,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Whimsicott",
    pokedex_num: 547,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Petilil",
    pokedex_num: 548,
    available_generations: ["5", "6", "7", "8", 'PLA'],
  },
  {
    name: "Lilligant",
    pokedex_num: 549,
    available_generations: ["5", "6", "7", "8", 'PLA'],
  },
  {
    name: "Basculin",
    pokedex_num: 550,
    available_generations: ["5", "6", "7", "8", 'PLA'],
  },
  {
    name: "Sandile",
    pokedex_num: 551,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Krokorok",
    pokedex_num: 552,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Krookodile",
    pokedex_num: 553,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Darumaka",
    pokedex_num: 554,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Darmanitan",
    pokedex_num: 555,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Maractus",
    pokedex_num: 556,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Dwebble",
    pokedex_num: 557,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Crustle",
    pokedex_num: 558,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Scraggy",
    pokedex_num: 559,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Scrafty",
    pokedex_num: 560,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Sigilyph",
    pokedex_num: 561,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Yamask",
    pokedex_num: 562,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Cofagrigus",
    pokedex_num: 563,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Tirtouga",
    pokedex_num: 564,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Carracosta",
    pokedex_num: 565,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Archen",
    pokedex_num: 566,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Archeops",
    pokedex_num: 567,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Trubbish",
    pokedex_num: 568,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Garbodor",
    pokedex_num: 569,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Zorua",
    pokedex_num: 570,
    available_generations: ["5", "6", "7", "8", 'PLA'],
  },
  {
    name: "Zoroark",
    pokedex_num: 571,
    available_generations: ["5", "6", "7", "8", 'PLA'],
  },
  {
    name: "Minccino",
    pokedex_num: 572,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Cinccino",
    pokedex_num: 573,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Gothita",
    pokedex_num: 574,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Gothorita",
    pokedex_num: 575,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Gothitelle",
    pokedex_num: 576,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Solosis",
    pokedex_num: 577,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Duosion",
    pokedex_num: 578,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Reuniclus",
    pokedex_num: 579,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Ducklett",
    pokedex_num: 580,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Swanna",
    pokedex_num: 581,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Vanillite",
    pokedex_num: 582,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Vanillish",
    pokedex_num: 583,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Vanilluxe",
    pokedex_num: 584,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Deerling",
    pokedex_num: 585,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Sawsbuck",
    pokedex_num: 586,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Emolga",
    pokedex_num: 587,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Karrablast",
    pokedex_num: 588,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Escavalier",
    pokedex_num: 589,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Foongus",
    pokedex_num: 590,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Amoonguss",
    pokedex_num: 591,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Frillish",
    pokedex_num: 592,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Jellicent",
    pokedex_num: 593,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Alomomola",
    pokedex_num: 594,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Joltik",
    pokedex_num: 595,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Galvantula",
    pokedex_num: 596,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Ferroseed",
    pokedex_num: 597,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Ferrothorn",
    pokedex_num: 598,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Klink",
    pokedex_num: 599,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Klang",
    pokedex_num: 600,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Klinklang",
    pokedex_num: 601,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Tynamo",
    pokedex_num: 602,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Eelektrik",
    pokedex_num: 603,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Eelektross",
    pokedex_num: 604,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Elgyem",
    pokedex_num: 605,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Beheeyem",
    pokedex_num: 606,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Litwick",
    pokedex_num: 607,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Lampent",
    pokedex_num: 608,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Chandelure",
    pokedex_num: 609,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Axew",
    pokedex_num: 610,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Fraxure",
    pokedex_num: 611,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Haxorus",
    pokedex_num: 612,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Cubchoo",
    pokedex_num: 613,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Beartic",
    pokedex_num: 614,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Cryogonal",
    pokedex_num: 615,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Shelmet",
    pokedex_num: 616,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Accelgor",
    pokedex_num: 617,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Stunfisk",
    pokedex_num: 618,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Mienfoo",
    pokedex_num: 619,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Mienshao",
    pokedex_num: 620,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Druddigon",
    pokedex_num: 621,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Golett",
    pokedex_num: 622,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Golurk",
    pokedex_num: 623,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Pawniard",
    pokedex_num: 624,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Bisharp",
    pokedex_num: 625,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Bouffalant",
    pokedex_num: 626,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Rufflet",
    pokedex_num: 627,
    available_generations: ["5", "6", "7", "8", 'PLA'],
  },
  {
    name: "Braviary",
    pokedex_num: 628,
    available_generations: ["5", "6", "7", "8", 'PLA'],
  },
  {
    name: "Vullaby",
    pokedex_num: 629,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Mandibuzz",
    pokedex_num: 630,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Heatmor",
    pokedex_num: 631,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Durant",
    pokedex_num: 632,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Deino",
    pokedex_num: 633,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Zweilous",
    pokedex_num: 634,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Hydreigon",
    pokedex_num: 635,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Larvesta",
    pokedex_num: 636,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Volcarona",
    pokedex_num: 637,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Cobalion",
    pokedex_num: 638,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Terrakion",
    pokedex_num: 639,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Virizion",
    pokedex_num: 640,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Tornadus",
    pokedex_num: 641,
    available_generations: ["5", "6", "7", 'PLA'],
  },
  {
    name: "Thundurus",
    pokedex_num: 642,
    available_generations: ["5", "6", "7", 'PLA'],
  },
  {
    name: "Reshiram",
    pokedex_num: 643,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Zekrom",
    pokedex_num: 644,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Landorus",
    pokedex_num: 645,
    available_generations: ["5", "6", "7", 'PLA'],
  },
  {
    name: "Kyurem",
    pokedex_num: 646,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Keldeo",
    pokedex_num: 647,
    available_generations: ["5", "6", "7", "8"],
  },
  {
    name: "Meloetta",
    pokedex_num: 648,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Genesect",
    pokedex_num: 649,
    available_generations: ["5", "6", "7"],
  },
  {
    name: "Chespin",
    pokedex_num: 650,
    available_generations: ["6", "7"],
  },
  {
    name: "Quilladin",
    pokedex_num: 651,
    available_generations: ["6", "7"],
  },
  {
    name: "Chesnaught",
    pokedex_num: 652,
    available_generations: ["6", "7"],
  },
  {
    name: "Fennekin",
    pokedex_num: 653,
    available_generations: ["6", "7"],
  },
  {
    name: "Braixen",
    pokedex_num: 654,
    available_generations: ["6", "7"],
  },
  {
    name: "Delphox",
    pokedex_num: 655,
    available_generations: ["6", "7"],
  },
  {
    name: "Froakie",
    pokedex_num: 656,
    available_generations: ["6", "7"],
  },
  {
    name: "Frogadier",
    pokedex_num: 657,
    available_generations: ["6", "7"],
  },
  {
    name: "Greninja",
    pokedex_num: 658,
    available_generations: ["6", "7"],
  },
  {
    name: "Bunnelby",
    pokedex_num: 659,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Diggersby",
    pokedex_num: 660,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Fletchling",
    pokedex_num: 661,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Fletchinder",
    pokedex_num: 662,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Talonflame",
    pokedex_num: 663,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Scatterbug",
    pokedex_num: 664,
    available_generations: ["6", "7"],
  },
  {
    name: "Spewpa",
    pokedex_num: 665,
    available_generations: ["6", "7"],
  },
  {
    name: "Vivillon",
    pokedex_num: 666,
    available_generations: ["6", "7"],
  },
  {
    name: "Litleo",
    pokedex_num: 667,
    available_generations: ["6", "7"],
  },
  {
    name: "Pyroar",
    pokedex_num: 668,
    available_generations: ["6", "7"],
  },
  {
    name: "Flabébé",
    pokedex_num: 669,
    available_generations: ["6", "7"],
  },
  {
    name: "Floette",
    pokedex_num: 670,
    available_generations: ["6", "7"],
  },
  {
    name: "Florges",
    pokedex_num: 671,
    available_generations: ["6", "7"],
  },
  {
    name: "Skiddo",
    pokedex_num: 672,
    available_generations: ["6", "7"],
  },
  {
    name: "Gogoat",
    pokedex_num: 673,
    available_generations: ["6", "7"],
  },
  {
    name: "Pancham",
    pokedex_num: 674,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Pangoro",
    pokedex_num: 675,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Furfrou",
    pokedex_num: 676,
    available_generations: ["6", "7"],
  },
  {
    name: "Espurr",
    pokedex_num: 677,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Meowstic",
    pokedex_num: 678,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Honedge",
    pokedex_num: 679,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Doublade",
    pokedex_num: 680,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Aegislash",
    pokedex_num: 680,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Spritzee",
    pokedex_num: 682,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Aromatisse",
    pokedex_num: 683,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Swirlix",
    pokedex_num: 684,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Slurpuff",
    pokedex_num: 685,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Inkay",
    pokedex_num: 686,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Malamar",
    pokedex_num: 687,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Binacle",
    pokedex_num: 688,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Barbaracle",
    pokedex_num: 689,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Skrelp",
    pokedex_num: 690,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Dragalge",
    pokedex_num: 691,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Clauncher",
    pokedex_num: 692,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Clawitzer",
    pokedex_num: 693,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Helioptile",
    pokedex_num: 694,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Heliolisk",
    pokedex_num: 695,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Tyrunt",
    pokedex_num: 696,
    available_generations: ["6", "7"],
  },
  {
    name: "Tyrantrum",
    pokedex_num: 697,
    available_generations: ["6", "7"],
  },
  {
    name: "Amaura",
    pokedex_num: 698,
    available_generations: ["6", "7"],
  },
  {
    name: "Aurorus",
    pokedex_num: 699,
    available_generations: ["6", "7"],
  },
  {
    name: "Sylveon",
    pokedex_num: 700,
    available_generations: ["6", "7", "8", "PLA"],
  },
  {
    name: "Hawlucha",
    pokedex_num: 701,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Dedenne",
    pokedex_num: 702,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Carbink",
    pokedex_num: 703,
    available_generations: ["6", "7"],
  },
  {
    name: "Goomy",
    pokedex_num: 704,
    available_generations: ["6", "7", "8", 'PLA'],
  },
  {
    name: "Sliggoo",
    pokedex_num: 705,
    available_generations: ["6", "7", "8", 'PLA'],
  },
  {
    name: "Goodra",
    pokedex_num: 706,
    available_generations: ["6", "7", "8", 'PLA'],
  },
  {
    name: "Klefki",
    pokedex_num: 707,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Phantump",
    pokedex_num: 708,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Trevenant",
    pokedex_num: 709,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Pumpkaboo",
    pokedex_num: 710,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Gourgeist",
    pokedex_num: 711,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Bergmite",
    pokedex_num: 712,
    available_generations: ["6", "7", "8", 'PLA'],
  },
  {
    name: "Avalugg",
    pokedex_num: 713,
    available_generations: ["6", "7", "8", 'PLA'],
  },
  {
    name: "Noibat",
    pokedex_num: 714,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Noivern",
    pokedex_num: 715,
    available_generations: ["6", "7", "8"],
  },
  {
    name: "Xerneas",
    pokedex_num: 716,
    available_generations: ["6", "7"],
  },
  {
    name: "Yveltal",
    pokedex_num: 717,
    available_generations: ["6", "7"],
  },
  {
    name: "Zygarde",
    pokedex_num: 718,
    available_generations: ["6", "7"],
  },
  {
    name: "Diancie",
    pokedex_num: 719,
    available_generations: ["6", "7"],
  },
  {
    name: "Hoopa",
    pokedex_num: 720,
    available_generations: ["6", "7"],
  },
  {
    name: "Volcanion",
    pokedex_num: 721,
    available_generations: ["6", "7"],
  },
  {
    name: "Rowlet",
    pokedex_num: 722,
    available_generations: ["7", "8", "PLA"],
  },
  {
    name: "Dartrix",
    pokedex_num: 723,
    available_generations: ["7", "8", "PLA"],
  },
  {
    name: "Decidueye",
    pokedex_num: 724,
    available_generations: ["7", "8", "PLA"],
  },
  {
    name: "Litten",
    pokedex_num: 725,
    available_generations: ["7", "8"],
  },
  {
    name: "Torracat",
    pokedex_num: 726,
    available_generations: ["7", "8"],
  },
  {
    name: "Incineroar",
    pokedex_num: 727,
    available_generations: ["7", "8"],
  },
  {
    name: "Popplio",
    pokedex_num: 728,
    available_generations: ["7", "8"],
  },
  {
    name: "Brionne",
    pokedex_num: 729,
    available_generations: ["7", "8"],
  },
  {
    name: "Primarina",
    pokedex_num: 730,
    available_generations: ["7", "8"],
  },
  {
    name: "Pikipek",
    pokedex_num: 731,
    available_generations: ["7"],
  },
  {
    name: "Trumbeak",
    pokedex_num: 732,
    available_generations: ["7"],
  },
  {
    name: "Toucannon",
    pokedex_num: 733,
    available_generations: ["7"],
  },
  {
    name: "Yungoos",
    pokedex_num: 734,
    available_generations: ["7"],
  },
  {
    name: "Gumshoos",
    pokedex_num: 735,
    available_generations: ["7"],
  },
  {
    name: "Grubbin",
    pokedex_num: 736,
    available_generations: ["7", "8"],
  },
  {
    name: "Charjabug",
    pokedex_num: 737,
    available_generations: ["7", "8"],
  },
  {
    name: "Vikavolt",
    pokedex_num: 738,
    available_generations: ["7", "8"],
  },
  {
    name: "Crabrawler",
    pokedex_num: 739,
    available_generations: ["7"],
  },
  {
    name: "Crabominable",
    pokedex_num: 740,
    available_generations: ["7"],
  },
  {
    name: "Oricorio",
    pokedex_num: 741,
    available_generations: ["7"],
  },
  {
    name: "Cutiefly",
    pokedex_num: 742,
    available_generations: ["7", "8"],
  },
  {
    name: "Ribombee",
    pokedex_num: 743,
    available_generations: ["7", "8"],
  },
  {
    name: "Rockruff",
    pokedex_num: 744,
    available_generations: ["7", "8"],
  },
  {
    name: "Lycanroc",
    pokedex_num: 745,
    available_generations: ["7", "8"],
  },
  {
    name: "Wishiwashi",
    pokedex_num: 746,
    available_generations: ["7", "8"],
  },
  {
    name: "Mareanie",
    pokedex_num: 747,
    available_generations: ["7", "8"],
  },
  {
    name: "Toxapex",
    pokedex_num: 748,
    available_generations: ["7", "8"],
  },
  {
    name: "Mudbray",
    pokedex_num: 749,
    available_generations: ["7", "8"],
  },
  {
    name: "Mudsdale",
    pokedex_num: 750,
    available_generations: ["7", "8"],
  },
  {
    name: "Dewpider",
    pokedex_num: 751,
    available_generations: ["7", "8"],
  },
  {
    name: "Araquanid",
    pokedex_num: 752,
    available_generations: ["7", "8"],
  },
  {
    name: "Fomantis",
    pokedex_num: 753,
    available_generations: ["7", "8"],
  },
  {
    name: "Lurantis",
    pokedex_num: 754,
    available_generations: ["7", "8"],
  },
  {
    name: "Morelull",
    pokedex_num: 755,
    available_generations: ["7", "8"],
  },
  {
    name: "Shiinotic",
    pokedex_num: 756,
    available_generations: ["7", "8"],
  },
  {
    name: "Salandit",
    pokedex_num: 757,
    available_generations: ["7", "8"],
  },
  {
    name: "Salazzle",
    pokedex_num: 758,
    available_generations: ["7", "8"],
  },
  {
    name: "Stufful",
    pokedex_num: 759,
    available_generations: ["7", "8"],
  },
  {
    name: "Bewear",
    pokedex_num: 760,
    available_generations: ["7", "8"],
  },
  {
    name: "Bounsweet",
    pokedex_num: 761,
    available_generations: ["7", "8"],
  },
  {
    name: "Steenee",
    pokedex_num: 762,
    available_generations: ["7", "8"],
  },
  {
    name: "Tsareena",
    pokedex_num: 763,
    available_generations: ["7", "8"],
  },
  {
    name: "Comfey",
    pokedex_num: 764,
    available_generations: ["7", "8"],
  },
  {
    name: "Oranguru",
    pokedex_num: 765,
    available_generations: ["7", "8"],
  },
  {
    name: "Passimian",
    pokedex_num: 766,
    available_generations: ["7", "8"],
  },
  {
    name: "Wimpod",
    pokedex_num: 767,
    available_generations: ["7", "8"],
  },
  {
    name: "Golisopod",
    pokedex_num: 768,
    available_generations: ["7", "8"],
  },
  {
    name: "Sandygast",
    pokedex_num: 769,
    available_generations: ["7", "8"],
  },
  {
    name: "Palossand",
    pokedex_num: 770,
    available_generations: ["7", "8"],
  },
  {
    name: "Pyukumuku",
    pokedex_num: 771,
    available_generations: ["7", "8"],
  },
  {
    name: "Type: Null",
    pokedex_num: 772,
    available_generations: ["7", "8"],
  },
  {
    name: "Silvally",
    pokedex_num: 773,
    available_generations: ["7", "8"],
  },
  {
    name: "Minior",
    pokedex_num: 774,
    available_generations: ["7"],
  },
  {
    name: "Komala",
    pokedex_num: 775,
    available_generations: ["7"],
  },
  {
    name: "Turtonator",
    pokedex_num: 776,
    available_generations: ["7", "8"],
  },
  {
    name: "Togedemaru",
    pokedex_num: 777,
    available_generations: ["7", "8"],
  },
  {
    name: "Mimikyu",
    pokedex_num: 778,
    available_generations: ["7", "8"],
  },
  {
    name: "Bruxish",
    pokedex_num: 779,
    available_generations: ["7"],
  },
  {
    name: "Drampa",
    pokedex_num: 780,
    available_generations: ["7", "8"],
  },
  {
    name: "Dhelmise",
    pokedex_num: 781,
    available_generations: ["7", "8"],
  },
  {
    name: "Jangmo-o",
    pokedex_num: 782,
    available_generations: ["7", "8"],
  },
  {
    name: "Hakamo-o",
    pokedex_num: 783,
    available_generations: ["7", "8"],
  },
  {
    name: "Kommo-o",
    pokedex_num: 784,
    available_generations: ["7", "8"],
  },
  {
    name: "Tapu Koko",
    pokedex_num: 785,
    available_generations: ["7"],
  },
  {
    name: "Tapu Lele",
    pokedex_num: 786,
    available_generations: ["7"],
  },
  {
    name: "Tapu Bulu",
    pokedex_num: 787,
    available_generations: ["7"],
  },
  {
    name: "Tapu Fini",
    pokedex_num: 788,
    available_generations: ["7"],
  },
  {
    name: "Cosmog",
    pokedex_num: 789,
    available_generations: ["7", "8"],
  },
  {
    name: "Cosmoem",
    pokedex_num: 790,
    available_generations: ["7", "8"],
  },
  {
    name: "Solgaleo",
    pokedex_num: 791,
    available_generations: ["7", "8"],
  },
  {
    name: "Lunala",
    pokedex_num: 792,
    available_generations: ["7", "8"],
  },
  {
    name: "Nihilego",
    pokedex_num: 793,
    available_generations: ["7"],
  },
  {
    name: "Buzzwole",
    pokedex_num: 794,
    available_generations: ["7"],
  },
  {
    name: "Pheromosa",
    pokedex_num: 795,
    available_generations: ["7"],
  },
  {
    name: "Xurkitree",
    pokedex_num: 796,
    available_generations: ["7"],
  },
  {
    name: "Celesteela",
    pokedex_num: 797,
    available_generations: ["7"],
  },
  {
    name: "Kartana",
    pokedex_num: 798,
    available_generations: ["7"],
  },
  {
    name: "Guzzlord",
    pokedex_num: 799,
    available_generations: ["7"],
  },
  {
    name: "Necrozma",
    pokedex_num: 800,
    available_generations: ["7", "8"],
  },
  {
    name: "Magearna",
    pokedex_num: 801,
    available_generations: ["7", "8"],
  },
  {
    name: "Marshadow",
    pokedex_num: 802,
    available_generations: ["7", "8"],
  },
  {
    name: "Poipole",
    pokedex_num: 803,
    available_generations: ["7"],
  },
  {
    name: "Naganadel",
    pokedex_num: 804,
    available_generations: ["7"],
  },
  {
    name: "Stakataka",
    pokedex_num: 805,
    available_generations: ["7"],
  },
  {
    name: "Blacephalon",
    pokedex_num: 806,
    available_generations: ["7"],
  },
  {
    name: "Zeraora",
    pokedex_num: 807,
    available_generations: ["7", "8"],
  },
  {
    name: "Meltan",
    pokedex_num: 808,
    available_generations: ["LGPE", "8"],
  },
  {
    name: "Melmetal",
    pokedex_num: 809,
    available_generations: ["LGPE", "8"],
  },
  {
    name: "Grookey",
    pokedex_num: 810,
    available_generations: ["8"],
  },
  {
    name: "Thwackey",
    pokedex_num: 811,
    available_generations: ["8"],
  },
  {
    name: "Rillaboom",
    pokedex_num: 812,
    available_generations: ["8"],
  },
  {
    name: "Scorbunny",
    pokedex_num: 813,
    available_generations: ["8"],
  },
  {
    name: "Raboot",
    pokedex_num: 814,
    available_generations: ["8"],
  },
  {
    name: "Cinderace",
    pokedex_num: 815,
    available_generations: ["8"],
  },
  {
    name: "Sobble",
    pokedex_num: 816,
    available_generations: ["8"],
  },
  {
    name: "Drizzile",
    pokedex_num: 817,
    available_generations: ["8"],
  },
  {
    name: "Inteleon",
    pokedex_num: 818,
    available_generations: ["8"],
  },
  {
    name: "Skwovet",
    pokedex_num: 819,
    available_generations: ["8"],
  },
  {
    name: "Greedent",
    pokedex_num: 820,
    available_generations: ["8"],
  },
  {
    name: "Rookidee",
    pokedex_num: 821,
    available_generations: ["8"],
  },
  {
    name: "Corvisquire",
    pokedex_num: 822,
    available_generations: ["8"],
  },
  {
    name: "Corviknight",
    pokedex_num: 823,
    available_generations: ["8"],
  },
  {
    name: "Blipbug",
    pokedex_num: 824,
    available_generations: ["8"],
  },
  {
    name: "Dottler",
    pokedex_num: 825,
    available_generations: ["8"],
  },
  {
    name: "Orbeetle",
    pokedex_num: 826,
    available_generations: ["8"],
  },
  {
    name: "Nickit",
    pokedex_num: 827,
    available_generations: ["8"],
  },
  {
    name: "Thievul",
    pokedex_num: 828,
    available_generations: ["8"],
  },
  {
    name: "Gossifleur",
    pokedex_num: 829,
    available_generations: ["8"],
  },
  {
    name: "Eldegoss",
    pokedex_num: 830,
    available_generations: ["8"],
  },
  {
    name: "Wooloo",
    pokedex_num: 831,
    available_generations: ["8"],
  },
  {
    name: "Dubwool",
    pokedex_num: 832,
    available_generations: ["8"],
  },
  {
    name: "Chewtle",
    pokedex_num: 833,
    available_generations: ["8"],
  },
  {
    name: "Drednaw",
    pokedex_num: 834,
    available_generations: ["8"],
  },
  {
    name: "Yamper",
    pokedex_num: 835,
    available_generations: ["8"],
  },
  {
    name: "Boltund",
    pokedex_num: 836,
    available_generations: ["8"],
  },
  {
    name: "Rolycoly",
    pokedex_num: 837,
    available_generations: ["8"],
  },
  {
    name: "Carkol",
    pokedex_num: 838,
    available_generations: ["8"],
  },
  {
    name: "Coalossal",
    pokedex_num: 839,
    available_generations: ["8"],
  },
  {
    name: "Applin",
    pokedex_num: 840,
    available_generations: ["8"],
  },
  {
    name: "Flapple",
    pokedex_num: 841,
    available_generations: ["8"],
  },
  {
    name: "Appletun",
    pokedex_num: 842,
    available_generations: ["8"],
  },
  {
    name: "Silicobra",
    pokedex_num: 843,
    available_generations: ["8"],
  },
  {
    name: "Sandaconda",
    pokedex_num: 844,
    available_generations: ["8"],
  },
  {
    name: "Cramorant",
    pokedex_num: 845,
    available_generations: ["8"],
  },
  {
    name: "Arrokuda",
    pokedex_num: 846,
    available_generations: ["8"],
  },
  {
    name: "Barraskewda",
    pokedex_num: 847,
    available_generations: ["8"],
  },
  {
    name: "Toxel",
    pokedex_num: 848,
    available_generations: ["8"],
  },
  {
    name: "Toxtricity",
    pokedex_num: 849,
    available_generations: ["8"],
  },
  {
    name: "Sizzlipede",
    pokedex_num: 850,
    available_generations: ["8"],
  },
  {
    name: "Centiskorch",
    pokedex_num: 851,
    available_generations: ["8"],
  },
  {
    name: "Clobbopus",
    pokedex_num: 852,
    available_generations: ["8"],
  },
  {
    name: "Grapploct",
    pokedex_num: 853,
    available_generations: ["8"],
  },
  {
    name: "Sinistea",
    pokedex_num: 854,
    available_generations: ["8"],
  },
  {
    name: "Polteageist",
    pokedex_num: 855,
    available_generations: ["8"],
  },
  {
    name: "Hatenna",
    pokedex_num: 856,
    available_generations: ["8"],
  },
  {
    name: "Hattrem",
    pokedex_num: 857,
    available_generations: ["8"],
  },
  {
    name: "Hatterene",
    pokedex_num: 858,
    available_generations: ["8"],
  },
  {
    name: "Impidimp",
    pokedex_num: 859,
    available_generations: ["8"],
  },
  {
    name: "Morgrem",
    pokedex_num: 860,
    available_generations: ["8"],
  },
  {
    name: "Grimmsnarl",
    pokedex_num: 861,
    available_generations: ["8"],
  },
  {
    name: "Obstagoon",
    pokedex_num: 862,
    available_generations: ["8"],
  },
  {
    name: "Perrserker",
    pokedex_num: 863,
    available_generations: ["8"],
  },
  {
    name: "Cursola",
    pokedex_num: 864,
    available_generations: ["8"],
  },
  {
    name: "Sirfetch'd",
    pokedex_num: 865,
    available_generations: ["8"],
  },
  {
    name: "Mr. Rime",
    pokedex_num: 866,
    available_generations: ["8"],
  },
  {
    name: "Runerigus",
    pokedex_num: 867,
    available_generations: ["8"],
  },
  {
    name: "Milcery",
    pokedex_num: 868,
    available_generations: ["8"],
  },
  {
    name: "Alcremie",
    pokedex_num: 869,
    available_generations: ["8"],
  },
  {
    name: "Falinks",
    pokedex_num: 870,
    available_generations: ["8"],
  },
  {
    name: "Pincurchin",
    pokedex_num: 871,
    available_generations: ["8"],
  },
  {
    name: "Snom",
    pokedex_num: 872,
    available_generations: ["8"],
  },
  {
    name: "Frosmoth",
    pokedex_num: 873,
    available_generations: ["8"],
  },
  {
    name: "Stonjourner",
    pokedex_num: 874,
    available_generations: ["8"],
  },
  {
    name: "Eiscue",
    pokedex_num: 875,
    available_generations: ["8"],
  },
  {
    name: "Indeedee",
    pokedex_num: 876,
    available_generations: ["8"],
  },
  {
    name: "Morpeko",
    pokedex_num: 877,
    available_generations: ["8"],
  },
  {
    name: "Cufant",
    pokedex_num: 878,
    available_generations: ["8"],
  },
  {
    name: "Copperajah",
    pokedex_num: 879,
    available_generations: ["8"],
  },
  {
    name: "Dracozolt",
    pokedex_num: 880,
    available_generations: ["8"],
  },
  {
    name: "Arctozolt",
    pokedex_num: 881,
    available_generations: ["8"],
  },
  {
    name: "Dracovish",
    pokedex_num: 882,
    available_generations: ["8"],
  },
  {
    name: "Arctovish",
    pokedex_num: 883,
    available_generations: ["8"],
  },
  {
    name: "Duraludon",
    pokedex_num: 884,
    available_generations: ["8"],
  },
  {
    name: "Dreepy",
    pokedex_num: 885,
    available_generations: ["8"],
  },
  {
    name: "Drakloak",
    pokedex_num: 886,
    available_generations: ["8"],
  },
  {
    name: "Dragapult",
    pokedex_num: 887,
    available_generations: ["8"],
  },
  {
    name: "Zacian",
    pokedex_num: 888,
    available_generations: ["8"],
  },
  {
    name: "Zamazenta",
    pokedex_num: 889,
    available_generations: ["8"],
  },
  {
    name: "Eternatus",
    pokedex_num: 890,
    available_generations: ["8"],
  },
  {
    name: "Kubfu",
    pokedex_num: 891,
    available_generations: ["8"],
  },
  {
    name: "Urshifu",
    pokedex_num: 892,
    available_generations: ["8"],
  },
  {
    name: "Zarude",
    pokedex_num: 893,
    available_generations: ["8"],
  },
  {
    name: "Regieleki",
    pokedex_num: 894,
    available_generations: ["8"],
  },
  {
    name: "Regidrago",
    pokedex_num: 895,
    available_generations: ["8"],
  },
  {
    name: "Glastrier",
    pokedex_num: 896,
    available_generations: ["8"],
  }, 
  {
    name: "Spectrier",
    pokedex_num: 897,
    available_generations: ["8"],
  },
  {
    name: "Calyrex",
    pokedex_num: 898,
    available_generations: ["8"],
  },
  {
    name: "Wyrdeer",
    pokedex_num: 899,
    available_generations: ["PLA"],
  },
  {
    name: "Kleavor",
    pokedex_num: 900,
    available_generations: ["PLA"],
  },
  {
    name: "Ursaluna",
    pokedex_num: 901,
    available_generations: ["PLA"],
  },
  {
    name: "Basculegion",
    pokedex_num: 902,
    available_generations: ["PLA"],
  },
  {
    name: "Sneasler",
    pokedex_num: 903,
    available_generations: ["PLA"],
  },
  {
    name: "Overqwil",
    pokedex_num: 904,
    available_generations: ["PLA"],
  },
  {
    name: "Enamorus",
    pokedex_num: 905,
    available_generations: ["PLA"],
  }
];
